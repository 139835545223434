import React from 'react';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

interface LogoProps {
	title?: string;
	width?: number;
	theme?: 'light' | 'dark';
	imgStyles?: object;
}

const Title = styled.b`
	margin-left: 10px;
	font-size: 1.1rem;
	vertical-align: middle;
	font-weight: 800;
	font-family: Montserrat, Roboto, sans-serif;
	color: ${(props: LogoProps) => props.theme == 'dark' ? '#000' : '#fff'};

	@media (max-width: 990px) {
		display: none;
	}
`;

const getImageFromWidth = (data: any, width: number = 50) => {
	switch (width) {
		case 30:
			return data.logo.childImageSharp['fx30'];
		case 90:
			return data.logo.childImageSharp['fx90'];
		default:
			return data.logo.childImageSharp['fx50'];
	}
};

function Logo({ width, title, theme, imgStyles, ...rest }: LogoProps & React.HTMLAttributes<HTMLDivElement>) {
	return (
		<StaticQuery
			query={logoQuery}
			render={data => {
				return (
					<div {...rest}>
						<Img style={imgStyles || {}} fixed={getImageFromWidth(data, width || 50)}/>

						{title && <Title theme={theme}>{title}</Title>}
					</div>
				);
			}}
		/>
	);
}

export default Logo;

const logoQuery = graphql`
	query {
		logo: file(relativePath:{regex:"/logo.png/"}) {
			childImageSharp {
				fx30: fixed(quality: 100, width: 30) {
					...GatsbyImageSharpFixed
				}
				fx50: fixed(quality: 100, width: 50) {
					...GatsbyImageSharpFixed
				}
				fx90: fixed(quality: 100, width: 90) {
					...GatsbyImageSharpFixed
				}
			}
		}
	}
`;

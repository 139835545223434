import * as React from 'react';
import { ThemeProvider } from 'styled-components';

export default ({ children }: any) => {
	return <React.Fragment>
		<ThemeProvider theme={{}}>
			<React.Fragment>{children}</React.Fragment>
		</ThemeProvider>
	</React.Fragment>;
};
